@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  background-color: #fffff9;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.learn-main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 1;
  transition: opacity 1.5s;

  &.fade-in {
    opacity: 1;
  }
}

.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 60px;
  color: #000000;
  margin: 20px;
  margin-bottom: 0px;
  z-index: 1;
  animation: fadeIn 1.5s forwards;
}

.title-bolded {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 60px;
  color: #fffff9;
  text-shadow: // Outline effect
    -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

.learn-underline {
  width: 24rem;
  animation: fadeIn 1.5s forwards;
}

.subtitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  width: 50rem;
  animation: fadeIn 1.5s forwards;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1.5s forwards;
}

.form-group {
  padding-top: 20px;
}

.name-input {
  width: 375px;
}

input {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #09cf64;
  background-color: transparent;
  height: 20px;
  border: 2px solid #09cf64;
  border-radius: 25px;
  resize: none;
  align-items: center;
  padding: 10px;
}

select {
  width: 375px;
  margin-left: 20px;
  padding-left: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #09cf64;
  background-color: transparent;
  height: 44px;
  border: 2px solid #09cf64;
  border-radius: 25px;
  resize: none;
  align-items: center;
  padding: 10px;
}

textarea {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #09cf64;
  background-color: transparent;
  height: 20px;
  padding: 10px;
  border: 2px solid #09cf64;
  border-radius: 25px;
  resize: none;
  line-height: 20px;
}

::placeholder {
  color: #09cf64;
  line-height: 20px;
}

.email-input,
.message-input {
  width: 770px;
}

.submit-button {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #fffff9;
  width: 150px;
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: #09cf64;
  margin-top: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1000px) {
  .subtitle {
    width: 80%;
  }

  .row {
    flex-direction: column;
    justify-content: center;
  }

  .name-input, .email-input, .message-input {
    width: 575px;
  }

  select {
    margin-left: unset;
    width: 595px; 
  }
}

@media (max-width: 700px) {
  .name-input, .email-input, .message-input {
    width: 475px;
  }

  select {
    width: 495px; 
  }
}

@media (max-width: 600px) {
  .name-input, .email-input, .message-input {
    width: 375px;
  }

  select {
    width: 395px; 
  }
}

@media (max-width: 500px) {
  .name-input, .email-input, .message-input {
    width: 275px;
  }

  .row {
    padding-top: 0;
  }

  select {
    width: 295px; 
  }
}

@media (max-width: 450px) {
  .title {
      font-size: 45px;
  }

  .title-bolded {
      font-size: 45px;
  }

  .learn-underline {
      width: 18rem;
  }

  .subtitle {
      font-size: 22px;
      width: 90%;
  }

  .name-input, .email-input, .message-input {
    width: 175px;
  }

  select {
    width: 195px; 
  }
}