@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  background-color: #fffff9;
  margin: 0;
  padding: 0;
}

.how-main {
  width: 100%;
  position: relative;
  min-height: 100vh;
  background-image: url("../images/GreenWaveBackground.png");
  background-size: contain;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  overflow: unset;

  &.fade-in {
    animation: fadeIn 1s forwards;
  }

  &.fade-out {
    animation: fadeOut 1s forwards;
  }

  .content-card {
    text-align: center;

    .title {
      font-family: "Montserrat", sans-serif;
      font-weight: 800;
      font-size: 60px;
      color: #000000;
      margin: 20px;
      margin-bottom: 0px;
      z-index: 1;
      animation: fadeIn 2s forwards;
    }

    .title-bolded {
      font-family: "Montserrat", sans-serif;
      font-weight: 800;
      font-size: 60px;
      color: #fffff9;
      text-shadow: // Outline effect
        -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
    }

    .how-underline {
      width: 25rem;
      animation: fadeIn 2s forwards;
    }

    .subtitle {
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      width: 54rem;
      animation: fadeIn 2s forwards;
    }

    .cow {
      width: 32rem;
      position: absolute;
      bottom: 12%;
      left: 5%;
      animation: moveUp 1s forwards;
    }

    .background-wave {
      display: none;
    }

    .reader {
      width: 15rem;
      position: absolute;
      bottom: 12%;
      left: 40%;
      animation: moveUp 1s forwards;
    }

    .reader-mobile {
      display: none;
    }

    .app {
      width: 30rem;
      position: absolute;
      bottom: 12%;
      left: 55%;
      animation: moveUp 1s forwards;
    }

    .second-title {
      font-family: "Montserrat", sans-serif;
      font-weight: 800;
      font-size: 60px;
      color: #000000;
      position: absolute;
      width: 50%;
      top: 10%;
      left: 11%;
      animation: moveRight 1s forwards;
    }

    .second-subtitle {
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 30px;
      color: #000000;
      position: absolute;
      width: 60%;
      top: 35%;
      left: 5%;
      animation: moveRight 1s forwards;
    }

    .mockup {
      width: 19rem;
      position: absolute;
      left: 67%;
      animation: moveRight 1s forwards;
    }
  }

  .arrow-left {
    position: absolute;
    left: 1%;
    top: 35%;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .left-icon {
    width: 1rem;
  }

  .arrow-right {
    position: absolute;
    right: 1%;
    top: 35%;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .right-icon {
    width: 1rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes moveUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes moveDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes moveLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes moveRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@media (max-width: 1300px) {
  .content {
      .content-card {
        .reader {
          left: 45%;

        }
      }
  }
}

@media (max-width: 1200px) {
  .content {
      .content-card {
        .cow {
          left: 3%;
        }
        .app {
          left: 52%;
        }
      }
  }
}

@media (max-width: 1100px) {
  .content {
      .content-card {
        .cow {
          width: 29rem;
          left: 1%;
        }
        .reader {
          width: 13.5rem;
          left: 43%;
        }
        .app {
          width: 27rem;
          left: 52%;
        }
      }
  }
}

@media (max-width: 1000px) {
  .content {
      .content-card {
        .cow {
          width: 28rem;
          left: 1%;
        }
        .reader {
          width: 12rem;
          left: 41%;
        }
        .app {
          width: 26rem;
          left: 50%;
        }
      }
  }
}

@media (max-width: 800px) {
  .content {
      .content-card {
        .cow {
          width: 28rem;
          left: 1%;
        }
        .reader {
          width: 12rem;
          left: 41%;
        }
        .app {
          width: 26rem;
          left: 50%;
        }
      }
  }
}

@media (max-width: 450px) {
  .how-main {
      background-image: none;
  }

  .content {
      .content-card {
          .title {
            margin-left: unset;
            font-size: 45px;
            width: 95%;
          }

          .title-bolded {
              font-size: 45px;
          }

          .how-underline {
              width: 19rem;
          }

          .subtitle {
              font-size: 25px;
              width: 90%;
              position: absolute;
              left: 4%;
          }

          .cow {
              width: 80%;
              left: -5%;
              bottom: 35%;
          }

          .background-wave {
              display: unset;
              position: absolute;
              width: 350%;
              bottom: 5%;
              right: -20%;

          }

          .reader {
              display: none;
          }

          .reader-mobile {
              display: unset;
              width: 35%;
              position: absolute;
              left: 52%;
              bottom: 38.5%;
          }

          .app {
            width: 86%;
            left: 5%;
            bottom: 2%
          }

          .second-title {
            font-size: 40px;
            width: 90%;
            top: -1%;
            left: 5%;
          }
      
          .second-subtitle {
            font-size: 25px;
            width: 90%;
            left: 5.5%;
            top: 68%;
          }
      
          .mockup {
            width: 50%;
            left: 23.5%;
            top: 21%;
          }
      }

      .arrow-left {
        top: 30%;
      }

      .arrow-right {
        top: 30%;
      }
  }
}

@media (max-width: 415px) {
  .content {
      .content-card {
          .cow {
              width: 83%;
              bottom: 34%;
          }
        }
      }
    }

@media (max-width: 375px) {
.content {
    .content-card {
        .subtitle {
            font-size: 19px;
        }

        .cow {
            width: 70%;
            left: -2%;
            bottom: 30%;
        }

        .background-wave {
            bottom: -3%;
        }

        .reader-mobile {
            width: 29.5%;
            left: 55%;
            bottom: 39.5%;
        }

        .app {
          width: 73%;
          left: 11%;
          bottom: 2%
        }

        .second-title {
          font-size: 30px;
        }
    
        .second-subtitle {
          font-size: 17px;
          width: 90%;
          left: 5.5%;
          top: 72.5%;
        }
    
        .mockup {
          width: 50%;
          left: 23.5%;
          top: 15%;
        }
    }

    .arrow-left {
      top: 35%;
    }

    .arrow-right {
      top: 35%;
    }
}
}

@media (max-width: 360px) {
  .content {
      .content-card {
          .cow {
              width: 80%;
              left: -2%;
              bottom: 34%;
          }
  
          .background-wave {
              bottom: 3%;
          }
  
          .reader-mobile {
              width: 31.5%;
          }
  
          .app {
            width: 80%;
            left: 9%;
          }
  
          .second-title {
            width: 95%;
            left: 3%;
          }
      
          .second-subtitle {
            font-size: 20px;
            top: 68.5%;
          }
      
          .mockup {
            width: 53%;
            left: 24.5%;
          }
      }
  }
}

@media (max-height: 715px) {
  .content {
      .content-card {
          .subtitle {
            font-size: 19px;
          }
          .cow {
            width: 70%;
            left: -2%;
            bottom: 32%;
          }
  
          .background-wave {
            bottom: -5%;
          }
  
          .reader-mobile {
              width: 29.5%;
          }
  
          .app {
            width: 75%;
            left: 10%;
            bottom: -2%;
          }
  
          .second-title {
            font-size: 35px;
            width: 95%;
            left: 3%;
          }
      
          .second-subtitle {
            font-size: 18px;
            top: 72.5%;
          }
      
          .mockup {
            width: 45%;
            left: 28%;
            top: 18%;
          }
      }
  }
}

@media (max-height: 700px) {
  .content {
      .content-card {
          .cow {
            width: 72%;
            bottom: 28%;
          }
          .background-wave {
            bottom: -7%;
          }
          .reader-mobile {
            left: 56%;
            bottom: 36%;
          }
          .second-title {
            font-size: 32px;
          }
      }
  }
}



