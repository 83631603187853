@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
    background-color: #FFFFF9;
    margin: 0;
    padding: 0;
}

.about-main {
    width: 100%;
    min-height: 100vh; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
}

.title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 60px;
    color: #000000;
    margin: 20px;
    margin-bottom: 0px;
    z-index: 1; 
}

.title-bolded {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 60px; 
    color: #FFFFF9;
    text-shadow: // Outline effect
        -1px -1px 0 black,  
        1px -1px 0 black,
        -1px 1px 0 black,
        1px 1px 0 black; 
}

.about-underline {
    width: 20rem;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    margin: 0px 20px 0px 20px;
}

.team-pic {
    width: 30%;
    height: 30%;
    padding-right: 40px;
}

.team-desc {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 20px;
    width: 60%;
    text-align: justify;
}

@media (max-width: 1000px) {
    .row {
        flex-direction: column;
        align-items: center;
    }

    .team-pic {
        padding-right: unset;
        padding-top: 20px;
        width: 45%;
        height: 45%;
    }

    .team-desc {
        width: 90%;
        text-align: justify;
    }
}

@media (max-width: 450px) {
    .about-underline {
        width: 15rem;
    }
    .about-main {
        background-image: none;
    }

    .team-desc {
        width: 80%;
    }
}