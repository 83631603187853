@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
    background-color: #FFFFF9;
    margin: 0;
    padding: 0;
}

.home-main {
    width: 100%;
    position: relative;
    min-height: 100vh; 
    background-image: url('../images/GreenWaveBackground.png');
    background-size: contain;
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.content {
    width: 100%;
    display: flex; 
    min-height: 100vh;
    overflow: unset;

    .mission-content {
        text-align: center;

        .title {
            font-family: 'Montserrat', sans-serif;
            font-weight: 800;
            font-size: 60px;
            color: #000000;
            margin: 20px;
            margin-bottom: 0px;
            z-index: 1; 
        }
        
        .title-bolded {
            font-family: 'Montserrat', sans-serif;
            font-weight: 800;
            font-size: 60px; 
            color: #FFFFF9;
            text-shadow: // Outline effect
                -1px -1px 0 black,  
                1px -1px 0 black,
                -1px 1px 0 black,
                1px 1px 0 black; 
        }
        
        .home-underline {
            width: 25rem;
        }

        .subtitle {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            width: 38rem;
        }

        .farmers {
            width: 32rem;
            position: absolute;
            bottom: 12%;
            left: 30%; 
            transform: translateX(-50%);
        }

        .background-wave {
            display: none;
        }

        .cow-rocket {
            width: 20rem;
            position: absolute;
            bottom: 35%;
            left: 70%;
            transform: translateX(-50%);
        }
    }
}

@media (max-width: 1300px) {
    .content {
        .mission-content {
            .cow-rocket {
                bottom: 30%;
            }
        }
    }
}

@media (max-width: 1200px) {
    .content {
        .mission-content {
            .cow-rocket {
                bottom: 25%;
            }
        }
    }
}

@media (max-width: 1100px) {
    .content {
        .mission-content {
            .cow-rocket {
                bottom: 20%;
            }
        }
    }
}

@media (max-width: 1000px) {
    .content {
        .mission-content {
            .farmers {
                left: 25%;
                bottom: 8%;
            }

            .cow-rocket {
                left: 75%;
            }
        }
    }
}

@media (max-width: 800px) {
    .content {
        .mission-content {
            .farmers {
                width: 65%;
                left: 25%
            }

            .cow-rocket {
                width: 40%;
            }
        }
    }
}

@media (max-width: 700px) {
    .content {
        .mission-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            .subtitle {
                width: 90%;
            }
        }
    }
}

@media (max-width: 450px) {
    .home-main {
        background-image: none;
    }

    .content {
        .mission-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                font-size: 45px;
            }

            .title-bolded {
                font-size: 45px;
            }

            .home-underline {
                width: 18rem;
            }

            .subtitle {
                font-size: 25px;
                width: 90%;
            }

            .farmers {
                width: 110%;
                left: 50%;
                bottom: 33%;
            }

            .background-wave {
                display: unset;
                position: absolute;
                width: 350%;
                bottom: 5%;
                right: -20%;

            }

            .cow-rocket {
                width: 70%;
                left: 60%;
                bottom: 10%
            }
        }
    }
}

@media (max-width: 375px) { //iPhone SE

    .content {
        .mission-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .subtitle {
                font-size: 23px;
                width: 90%;
            }

            .farmers {
                width: 100%;
                left: 50%;
                bottom: 25%;
            }

            .background-wave {
                display: unset;
                position: absolute;
                width: 350%;
                bottom: -8%;
                right: -20%;

            }

            .cow-rocket {
                width: 60%;
                bottom: 5%
            }
        }
    }
}

@media (max-width: 375px) { //iPhone SE

    .content {
        .mission-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .subtitle {
                font-size: 23px;
                width: 90%;
            }

            .farmers {
                width: 100%;
                left: 50%;
                bottom: 25%;
            }

            .background-wave {
                width: 350%;
                bottom: -8%;
                right: -20%;

            }

            .cow-rocket {
                width: 60%;
                bottom: 5%
            }
        }
    }
}

@media (max-width: 360px) { //Samsung Galaxy S8+

    .content {
        .mission-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .subtitle {
                font-size: 23px;
                width: 90%;
            }

            .farmers {
                width: 110%;
                left: 50%;
                bottom: 30%;
            }

            .background-wave {
                width: 350%;
                bottom: 1%;
                right: -20%;

            }

            .cow-rocket {
                width: 70%;
                bottom: 10%
            }
        }
    }
}

@media (max-height: 714px) { 
    .content {
        .mission-content {
            .subtitle {
                font-size: 23px;
                width: 95%;
            }
            .farmers {
                width: 100%;
                left: 50%;
                bottom: 25%;
            }
            .background-wave {
                width: 325%;
                bottom: -5%;
                right: -20%;

            }
            .cow-rocket {
                width: 55%;
                bottom: 10%
            }
        }
    }
}

@media (max-height: 700px) { 
    .content {
        .mission-content {
            .subtitle {
                font-size: 22px;
                width: 95%;
            }
            .farmers {
                width: 100%;
                left: 50%;
                bottom: 25%;
            }
            .background-wave {
                width: 325%;
                bottom: -5%;
                right: -20%;

            }
            .cow-rocket {
                width: 55%;
                bottom: 10%
            }
        }
    }
}
