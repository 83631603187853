@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.navbar {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  background-color: transparent;
  color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
}

.logo {
  width: 10rem;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger-icon {
  width: 2rem;;
}

.navbar-links {
  list-style: none;
  display: flex;
  align-items: center;
}

.navbar-links li {
  margin: 0 1rem;
}

.navbar-links a {
  color: black;
  text-decoration: none;
  font-size: 1rem;
}

.navbar-links-mobile {
  display: none;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.navbar-links-mobile li {
  margin: 0.5rem 0;
}

.navbar-links-mobile a {
  text-decoration: none;
  color: black;
}

.language-dropdown {
  position: relative;
}

.dropdown-trigger {
  cursor: pointer;
}

.language-button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.language-icon {
  max-width: 20px;
  margin-right: 8px;
}

.arrow {
  width: 1rem;
  padding-left: 7px;
}

.language-dropdown {
  position: relative;
}

.dropdown-trigger {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 105%;
  right: 0;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 10px 0px;
  width: 120px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease;
  z-index: 1000;
}

.dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.hamburger-dropdown-menu {
  position: absolute;
  top: 105%;
  left: -22%;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 10px 0px;
  width: 120px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease;
  z-index: 1000;
  display: flex;
  justify-content: center;
}

.hamburger-dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu-space {
  padding-left: unset;
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
  text-align: left;

  &:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
}

.dropdown-item img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.dropdown-item a {
  color: black;
  text-decoration: none;
  transition: color 0.3s;
}

.dropdown-item:hover img {
  opacity: 1;
}

.dropdown-item:hover a {
  color: #09CF64;
}

.about-us-button, .how-button, .learn-more-button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  border: none;
  background-color: transparent;
}

.learn-more-button {
  border-radius: 20px;
  background-color: #09CF64;
  width: 125px;
  height: 35px;
}

.learn-more-button > a {
  color: #FFFFF9;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .navbar-links-mobile {
    display: flex;
  }

  .hamburger-menu {
    display: flex;
  }
}
